@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}
.mdc-menu .mdc-deprecated-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-deprecated-list,
.mdc-menu .mdc-list {
  /* @alternate */
  position: relative;
}
.mdc-menu .mdc-deprecated-list .mdc-elevation-overlay,
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  /*rtl:ignore*/
  left: 0;
}
.mdc-menu .mdc-deprecated-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-deprecated-list-item {
  user-select: none;
}
.mdc-menu .mdc-deprecated-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__text,
.mdc-menu a.mdc-deprecated-list-item .mdc-deprecated-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-deprecated-list-item {
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 56px;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-deprecated-list-item, .mdc-menu__selection-group .mdc-deprecated-list-item[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 16px;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 56px;
  /*rtl:end:ignore*/
}

.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  /*rtl:ignore*/
  left: 16px;
  /* @noflip */
  /*rtl:ignore*/
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  left: initial;
  /* @noflip */
  /*rtl:ignore*/
  right: 16px;
  /*rtl:end:ignore*/
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}